import React from "react";
import useStyles from "./MainDashboard.styles";
import { connect } from "react-redux";
import ChurnRate from "../ChurnRate/ChurnRate";
import RedemptionRate from "../RedemptionRate/RedemptionRate";
import Users from "../Users/Users";
import SalesChart from "../SalesChart/SalesChart";
import UsersPieChart from "../UsersPieChart/UsersPieChart";
import GrowthRate from "../GrowthRate/GrowthRate";

const MainDashboard = ({ jwt }) => {
  const { analyticsContainer } = useStyles({
    active: ""
  });

  return (
    <>
      <section className={analyticsContainer}>
        <SalesChart jwt={jwt} />
      </section>
      <section className={analyticsContainer}>
        <ChurnRate jwt={jwt} />
      </section>
      <section className={analyticsContainer}>
        <RedemptionRate jwt={jwt} />
      </section>
      <section className={analyticsContainer}>
        <GrowthRate jwt={jwt} />
      </section>
      <section className={analyticsContainer}>
        <Users jwt={jwt} />
      </section>
      <section>
        <UsersPieChart jwt={jwt} />
      </section>
    </>
  );
};

export default connect(state => {
  return {
    jwt: state.user.user.jwt
  };
})(MainDashboard);
