import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  breakdown: {
    width: "100%",
    "& .breakdown-heading": {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      lineHeight: "20px",
      color: "#667085",
      marginBottom: "8px"
    },
    "& .breadkdown-analysis": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "16px"
    },
    "& .breakdown-amount": {
      fontFamily: "'Roboto', sans-serif",
      fontSize: "30px",
      fontWeight: "600",
      lineHeight: "44px",
      color: " #FFFFFF",
      letterSpacing: "-0.02em"
    },
    "& .breakdown-icon": {
      width: "44px",
      height: "44px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#312644",
      borderRadius: "50%"
    },
    "& .breakdown-further": {
      backgroundColor: "#1F2327",
      padding: "8px",
      width: "100%",
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "18px",
      marginTop: "8px"
    },
    "& .breakdown-further p": {
      margin: "0"
    },
    "& .breakdown-further-item": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "6px"
    },
    "& .breakdown-further-item-heading": {
      fontFamily: "'Inter', sans-serif",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "20px",
      color: "#FFFFFFB2",
      textAlign: "center"
    },
    "& .breakdown-further-item-amount": {
      fontFamily: "'Roboto', sans-serif",
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "20px",
      color: "#FFFFFF"
    }
  }
}));

export default useStyles;
