import React from "react";
import LoyaltyUsersList from "../../../containers/LoyaltyUsersList";

const LoyaltyUserListPage = () => {
  return (
    <div>
      <LoyaltyUsersList />
    </div>
  );
};

export default LoyaltyUserListPage;
