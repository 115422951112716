import React from "react";
import DashboardHomePage from "./DashboardHomePage";
import MainDashboard from "./MainDashboard/MainDashboard";
import PurchaseHistoryAnalyticsPage from "./PurchaseHistoryAnalyticsPage";
import LoyaltyStats from "./LoyaltyStats/LoyaltyStats";
// import VODStatsPage from "./VODStatsPage";
// import UsersStatsPage from "./UsersStatsPage";

export default [
  {
    path: "/dashboard/analytics",
    exact: true,
    component: () => <MainDashboard />
  },
  {
    path: "/dashboard/analytics/loyalty-analytics",
    exact: true,
    component: () => <LoyaltyStats />
  },
  // {
  //   path: "/dashboard/other-analytics",
  //   exact: true,
  //   component: () => <DashboardHomePage />
  // },
  {
    path: "/dashboard/analytics/purchase-history-analytics",
    exact: true,
    component: () => <PurchaseHistoryAnalyticsPage />
  }
  // {
  //   path: "/dashboard/vod-stats",
  //   exact: true,
  //   component: () => <VODStatsPage />
  // },
  // {
  //   path: "/dashboard/app-stats",
  //   exact: true,
  //   component: () => <UsersStatsPage />
  // }
];
