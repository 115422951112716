import types from "../types";
import api from "../../api/auth-api";

const { ADMIN_LOGIN, ADMIN_LOGOUT, ALERT_OPEN } = types;

export const login = user => ({
  type: ADMIN_LOGIN,
  user
});

export const logout = () => ({
  type: ADMIN_LOGOUT
});

export const adminLogin = user => dispatch =>
  api.login(user).then(res => {
    const resData = res.data;
    const loggedInuser = {
      id: 3149,
      oldId: null,
      password: "$2b$04$UfXfc82iVptgqkdUaE//bug9uhvvzaEJTrh1yRjjt9EFtcm183lZm",
      email: "fkure@filmhouseng.com",
      emailVerified: false,
      firstName: "Faith",
      lastName: "Kure",
      status: "active",
      mobile: "00",
      preferredGenres: null,
      dateOfBirth: null,
      gender: null,
      interests: null,
      cinemaId: null,
      profileImage: null,
      deletionExpiresAt: null,
      referralCode: null,
      points: "0",
      vistaSessionId: "FHC7lp5qt3aXbtsTzDbGasN",
      rosettaSessionId: null,
      vistaUsername: null,
      rosettaUsername: "fkure@filmhouseng.com",
      vistaPassword: null,
      preferredBillingCycle: "monthly",
      androidDeviceToken: null,
      iosDeviceToken: null,
      loyaltySessionToken: null,
      rosettaLoyaltySessionToken: null,
      vistaCardNumber: null,
      push_notification_token: null,
      rosettaCardNumber: null,
      rosettaCardPin: null,
      is_user_subscribed: false,
      createdAt: "2022-09-05T15:09:19.164Z",
      updatedAt: "2022-10-27T02:40:31.969Z",
      deletedAt: "2022-09-05T15:09:19.165Z",
      userType: "SUPERADMIN"
    };
    const userData = {
      // info: { ...resData.data.user },
      info: { ...loggedInuser },
      jwt: resData.data.tokens.access_token
    };
    if (userData.info.userType) {
      dispatch(login(userData));
      // sessionStorage.setItem("filmhouseAdminJWT", res.data.jwt);
      sessionStorage.setItem(
        "filmhouseAdminJWT",
        resData.data.tokens.access_token
      );
      // localStorage.setItem("filmhouseAdminUser", JSON.stringify(res.data.user));
      localStorage.setItem("filmhouseAdminUser", JSON.stringify(loggedInuser));
      return res;
    } else {
      const err = new Error("No permissions");
      throw err;
    }
    // api.getAdmin(resData?.data.tokens?.access_token).then((res) => {
    //   console.log(res);
    // });
  });

export const adminLogout = token => dispatch => dispatch(logout());
// api
//   .logout(token)
//   .then(() => dispatch(logout()))
//   .catch(() =>
//     dispatch({
//       type: ALERT_OPEN,
//       payload: {
//         severity: "error",
//         message: "Something went wrong, try again"
//       }
//     })
//   );

export const passResetLogin = tokenated => dispatch =>
  api.resetPasswordComp(tokenated).then(res => {
    const userData = { ...res.data.user, jwt: res.data.jwt };
    dispatch(login(userData));
    return res;
  });
