import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import "./App.css";
import ContainerOutlet from "./sitemap/ContainerOutlet";
import Alert from "./components/Alert";

console.log("test2");
const App = ({ signedIn }) => {
  return (
    <Router>
      <Alert />
      <ContainerOutlet signedIn={signedIn} />
    </Router>
  );
};

const mapStateToProps = state => ({
  signedIn: state?.user?.user?.signedIn
});

export default connect(mapStateToProps)(App);
