import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  detailsCardWrapper: {
    display: "grid",
    gap: "24px",
    gridTemplateColumns: "1fr 1fr 1fr",
    margin: "48px 0"
  },
  detailsCard: {
    backgroundColor: "#232329",
    border: "1px solid #5D5C5C",
    borderRadius: "5px",
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  detailsCardTitle: {
    fontSize: "16px",
    lineHeight: "18.75px",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontWeight: "medium"
  },
  detailsCardValue: {
    fontSize: "36px",
    lineHeight: "44px",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontWeight: "semibold",
    letterSpacing: "-0.02em"
  },
  detailsCardValueGender: {
    display: "flex",
    justifyContent: "space-between"
  },
  genderDetailsWrapper: {
    display: "flex",
    gap: "9px",
    alignItems: "center"
  },
  gender: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontWeight: "medium",
    backgroundColor: "#656569",
    padding: "2px 16px",
    borderRadius: "16px"
  }
}));

export default useStyles;
