import React from "react";
import useStyles from "./BreakdownText.styles";

const BreakdownText = ({
  title,
  breakdownAmount,
  icon,
  breakdownFurtherItems
}) => {
  const { breakdown } = useStyles();

  return (
    <div className={breakdown}>
      <p className="breakdown-heading">{title}</p>
      <div className="breadkdown-analysis">
        <p className="breakdown-amount">{breakdownAmount}</p>
        <div className="breakdown-icon">{icon}</div>
      </div>
      {breakdownFurtherItems ? (
        <div className="breakdown-further">
          {breakdownFurtherItems?.map((item, index) => (
            <div key={index} className="breakdown-further-item">
              <p className="breakdown-further-item-heading">{item.title}</p>
              <p className="breakdown-further-item-amount">{item.amount}</p>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default BreakdownText;
