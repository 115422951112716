import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  backBtn: {
    display: "flex"
  },
  section: {
    paddingLeft: 40
  },
  sectionContainer: {
    marginBottom: 30
  },
  titleStyle: {
    paddingLeft: 40,
    paddingTop: 13,
    color: "#FFFFFF",
    paddingBottom: theme.spacing(3)
  },
  profileHeading: {
    fontWeight: "500",
    fontSize: "34px",
    lineHeight: "40px",
    color: "#FFFFFF",
    marginBottom: "10px"
  },
  profileTagline: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#656569"
  },
  profileHead: {
    display: "flex",
    alignItems: "center",
    gap: "40px",
    marginBottom: "44px",
    marginTop: "54px"
  },
  avatarSection: {
    textAlign: "center",
    position: "relative"
  },
  uploadBtn: {
    position: "absolute",
    bottom: "36px",
    left: "50%",
    transform: "translateX(-50%)"
  },
  userName: {
    textAlign: "center"
  },
  changePicture: {
    color: "#7E599C",
    fontSize: "14px",
    margin: 0,
    letterSpacing: "0.48px",
    lineHeight: "33px"
  },
  userNameTypo: {
    margin: "auto",
    marginBottom: "20px",
    color: "rgba(255,255,255,0.87)",
    fontWeight: "500",
    fontSize: "35px"
  },
  pair: {
    display: "flex",
    marginTop: " -18px"
  },
  row: {
    display: "flex"
  },
  pointContainer: {
    gap: "300px"
  },
  tab: {
    display: "flex",
    flexDirection: "column",
    gap: "32px"
  },
  tabHeader: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: "16px",
    borderBottom: "1px solid #252525"
  },
  tabHeaderItem: {
    padding: "0 0 16px 0",
    borderBottom: "2px solid transparent",
    cursor: "pointer",
    color: "#8A8A8A",
    fontSize: "20px",
    lineHeight: "26px",
    fontWeight: "medium"
  },
  tabHeaderItemActive: {
    padding: "0 0 16px 0",
    color: "#A233E2",
    cursor: "pointer",
    borderBottom: "2px solid #A233E2",
    fontSize: "20px",
    lineHeight: "26px",
    fontWeight: "medium"
  },
  listItem: {
    display: "flex",
    minHeight: "64px",
    justifyContent: "space-between"
  },
  mainListText: {
    fontSize: "16px",
    letterSpacing: "0.5px",
    lineHeight: "24px",
    color: "rgba(255,255,255,0.87)"
  },
  subListText: {
    fontSize: "14.4px",
    letterSpacing: "0.14px",
    lineHeight: "21.66px",
    textAlign: "right",
    color: "rgba(255,255,255,0.6)"
  },
  marginTopSm: {
    marginTop: "20px"
  },
  root: {
    flex: 1,
    padding: "0px",
    backgroundColor: "rgba(0,0,0,0.2)"
  },
  title: {
    color: "rgba(255,255,255,0.87)",
    padding: "20px 17px",
    marginBottom: "-10px",
    fontSize: "23px"
  },
  doughnutTitle: {
    color: "rgba(255,255,255,0.87)",
    padding: "0px 23px",
    paddingTop: "23px",
    paddingBottom: "0px",
    marginBottom: "-10px",
    fontSize: "22px"
  },
  subText: {
    fontSize: "14px",
    color: "rgba(255,255,255,0.6)",
    padding: "10px 23px",
    marginBottom: "30px"
  },
  bigTitle: {
    fontSize: "50px",
    color: "rgba(255,255,255,0.87)",
    padding: "0px 23px"
  },
  chartContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px"
  },
  legendStyle: {
    // lineHeight: "40px",
    marginBottom: "10px",
    fontSize: "15px"
  },
  lineChartPane: {
    flex: 1,
    padding: "20px",
    backgroundColor: "rgb(22, 22, 27)"
  },
  profileTitle: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#656569",
    marginBottom: "8px"
  },
  profileValue: {
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#FFFFFF",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
  },
  profileContent: {
    position: "relative",
    overflow: "hidden",
    width: "80%"
  },
  profileContentDivider: {
    borderBottom: "1px solid rgba(101, 101, 105, 0.5)",
    padding: "18px 0",
    backgroundClip: "padding-box",
    position: "absolute",
    left: "40px",
    width: "100%",
    bottom: "20px"
  },
  profileContentButtonContainer: {
    marginTop: "44px"
  },
  profileContentPill: {
    borderRadius: "24px",
    backgroundColor: "#33383F",
    fontSize: "12px",
    padding: "5px 10px",
    color: "#B6B6B6",
    display: "inline-block",
    marginLeft: "8px",
    fontFamily: "Roboto"
  }
}));

export default useStyles;
