import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px"
  },
  search: {
    maxWidth: "694px",
    width: "60%"
  },
  rightAction: {
    display: "flex",
    gap: "23px",
    width: "35%",
    alignItems: "center"
  },
  dropDown: {
    maxWidth: "206px",
    width: "50%"
  },
  downloadBtn: {
    maxWidth: "246px",
    width: "50%"
  },
  success: {
    color: "#039855",
    fontWeight: 400
  },
  error: {
    color: "#F04438",
    fontWeight: 400
  }
}));

export default useStyles;
