import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";
import { CSVLink } from "react-csv";
import DownloadIcon from "@material-ui/icons/GetApp";
import { Grid, Button, TextField, MenuItem } from "@material-ui/core";
import PaperedInput from "../../../components/Inputs/PaperedInput";
import api from "../../../api/payments";
import { Alert } from "@material-ui/lab";
import {
  dateFromISO,
  debounce,
  getRequestError
} from "../../../utils/functions";
import { useCallback } from "react";
import DropDown from "../../../components/Inputs/Dropdown";
import useStyles from "./styles";

const dummyPayments = [
  {
    id: 1,
    date: "2021-09-01 12:00:00",
    bookingID: "123456",
    userID: "123456",
    email: "auser12345678@gmail.com",
    amount: "₦10,000",
    paymentType: "Single subscription",
    status: "success",
    user: "Subscribed"
  },
  {
    id: 2,
    date: "2021-09-01 12:00:00",
    bookingID: "123456",
    userID: "-",
    email: "user12345678@gmail.com",
    amount: "₦10,000",
    paymentType: "Multiple subscription",
    status: "success",
    user: "Guest"
  },
  {
    id: 3,
    date: "2021-09-01 12:00:00",
    bookingID: "123456",
    userID: "-",
    email: "user12345678@gmail.com",
    amount: "₦10,000",
    paymentType: "tickets",
    status: "failed",
    user: "Guest"
  },
  {
    id: 4,
    date: "2021-09-01 12:00:00",
    bookingID: "123456",
    userID: "595",
    email: "user12345678@gmail.com",
    amount: "₦10,000",
    paymentType: "concessions",
    status: "success",
    user: "Subscribed"
  },
  {
    id: 5,
    date: "2021-09-01 12:00:00",
    bookingID: "123456",
    userID: "595",
    email: "user12345678@gmail.com",
    amount: "₦10,000",
    paymentType: "concessions",
    status: "success",
    user: "Subscribed"
  },
  {
    id: 6,
    date: "2021-09-01 12:00:00",
    bookingID: "123456",
    userID: "595",
    email: "user12345678@gmail.com",
    amount: "₦10,000",
    paymentType: "concessions",
    status: "success",
    user: "Subscribed"
  },
  {
    id: 7,
    date: "2021-09-01 12:00:00",
    bookingID: "123456",
    userID: "595",
    email: "user12345678@gmail.com",
    amount: "₦10,000",
    paymentType: "concessions",
    status: "success",
    user: "Subscribed"
  },
  {
    id: 8,
    date: "2021-09-01 12:00:00",
    bookingID: "123456",
    userID: "595",
    email: "user12345678@gmail.com",
    amount: "₦10,000",
    paymentType: "concessions",
    status: "failed",
    user: "Subscribed"
  },
  {
    id: 9,
    date: "2021-09-01 12:00:00",
    bookingID: "123456",
    userID: "-",
    email: "user12345678@gmail.com",
    amount: "₦10,000",
    paymentType: "concessions",
    status: "success",
    user: "Guest"
  },
  {
    id: 10,
    date: "2021-09-01 12:00:00",
    bookingID: "123456",
    userID: "595",
    email: "user12345678@gmail.com",
    amount: "₦10,000",
    paymentType: "concessions",
    status: "success",
    user: "Subscribed"
  }
];

const PaymentLogs = () => {
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [userType, setUserType] = useState("ALL");
  const [payments, setPayments] = useState([]);
  const [error, setError] = useState({ isError: false, message: "" });
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const { jwt } = useSelector(state => state.user.user);

  const classes = useStyles();

  const handlePaymentLogsData = useCallback(async () => {
    try {
      let resData;
      if (searchInput) {
        resData = await api.searchPayments(`Bearer ${jwt}`, searchInput);
      } else {
        resData = await api.fetchPayments(`Bearer ${jwt}`, userType, page + 1);
      }
      const { totalCount = 0 } = resData.data.data;
      const paymentsData = searchInput
        ? resData?.data?.data ?? []
        : resData?.data?.data?.payment_logs ?? [];
      setLoading(false);
      const mappedPaymentData = paymentsData?.map((item, index) => {
        const { hour, minute, second, day, month, year } = dateFromISO(
          item?.createdAt ?? ""
        );
        return {
          date: `${year}-${month}-${day} ${hour}:${minute}:${second}`,
          bookingID: item?.bookingId ?? "",
          userID: item?.data?.meta?.userId ?? "_",
          id: item?.data?.id ?? index,
          email: item?.data?.meta?.email ?? "",
          amount: `₦${item?.data?.meta?.amount?.toLocaleString()}` ?? "",
          paymentType: item?.purchaseType ?? "",
          status: item?.status ?? ""
        };
      });
      setPayments(mappedPaymentData);
      setTotalData(totalCount);
    } catch (e) {
      setError({ isError: false, message: getRequestError(e) });
    }
  }, [jwt, page, searchInput, userType]);

  useEffect(() => {
    setLoading(true);
    setError({ isError: false, message: "" });
    handlePaymentLogsData();
  }, [page, jwt, searchInput, handlePaymentLogsData, userType]);

  const columns = [
    {
      field: "userID",
      headerName: "User ID",
      width: 110
    },
    { field: "user", headerName: "User", width: 120 },
    {
      field: "email",
      headerName: "Email",
      width: 250
    },
    {
      field: "paymentType",
      headerName: "Payment Type",
      width: 240
    },
    {
      field: "bookingID",
      headerName: "Booking ID",
      width: 140
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 120
    },
    { field: "date", headerName: "Time & Date", width: 180 },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: params => {
        return (
          <div>
            <div
              className={
                params.value === "success" ? classes.success : classes.error
              }
            >
              {params.value === "success" ? "Sucessful" : "Failed"}
            </div>
          </div>
        );
      }
    }
  ];

  return (
    <div>
      <>
        <div className={classes.header}>
          <div className={classes.search}>
            <PaperedInput
              onChange={e => {
                e.persist();
                debounce(() => setSearchInput(e.target.value), 2000);
              }}
              placeholder="Search payment logs..."
            />
          </div>
          <div className={classes.rightAction}>
            <div className={classes.dropDown}>
              <DropDown
                menuItems={["All", "Customer", "Guest"].map(item => ({
                  value: item.toUpperCase(),
                  name: item
                }))}
                value={userType}
                onChange={e => setUserType(e.target.value)}
              />
            </div>
            <div className={classes.downloadBtn}>
              <CSVLink data={payments} filename={"payment-logs.csv"}>
                <Button variant="contained" color="primary">
                  <DownloadIcon style={{ marginRight: "10px" }} /> DOWNLOAD CSV
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
        <div style={{ height: "90vh", width: "100%", marginTop: "20px" }}>
          {error.isError ? (
            <Alert severity="error">{error.message}</Alert>
          ) : (
            <DataGrid
              rows={dummyPayments}
              columns={columns}
              pageSize={10}
              rowHeight={50}
              headerHeight={60}
              disableColumnMenu={true}
              page={searchInput ? undefined : page}
              autoHeight={true}
              rowCount={searchInput ? undefined : totalData}
              onPageChange={
                searchInput
                  ? undefined
                  : page => {
                      setPage(page);
                    }
              }
              paginationMode={searchInput ? "client" : "server"}
              // loading={loading}
              rowsPerPageOptions={[10]}
            />
          )}
        </div>
      </>
    </div>
  );
};

export default PaymentLogs;
