import React from "react";
import useStyles from "./GraphBreakdown.styles";
import useMainStyles from "../MainDashboard/MainDashboard.styles";
import DownloadIcon from "@material-ui/icons/GetApp";
import BreakdownText from "../BreakdownText/BreakdownText";
import Subscription from "../../../../vectors/Subscription";
import Concession from "../../../../vectors/Concession";
import Ticket from "../../../../vectors/Ticket";
import { CSVLink } from "react-csv";

const GraphBreakdown = ({
  title,
  subTitle,
  totalBreakdown,
  breakdownOne,
  breakdownTwo,
  breakdownThree,
  subscriptionTitle,
  concessionTitle,
  ticketTitle,
  isLoading,
  chart,
  breakdown,
  activeFilter,
  extra,
  breakdownFurtherItems
}) => {
  const generateCSVData = (data, extra = "") =>
    data?.map(
      ({
        date,
        mobile_concessions,
        mobile_subscriptions,
        mobile_tickets,
        mobile_total,
        web_concessions,
        web_subscriptions,
        web_tickets,
        web_total,
        not_specified,
        total
      }) =>
        activeFilter === "web"
          ? {
              date,
              "Web Concessions": `${extra}${web_concessions}`,
              "Web Subscriptions": `${extra}${web_subscriptions}`,
              "Web Tickets": `${extra}${web_tickets}`,
              "Web Total": `${extra}${web_total}`
            }
          : activeFilter === "mobile"
          ? {
              date,
              "Mobile Concessions": `${extra}${mobile_concessions}`,
              "Mobile Subscriptions": `${extra}${mobile_subscriptions}`,
              "Mobile Tickets": `${extra}${mobile_tickets}`,
              "Mobile Total": `${extra}${mobile_total}`
            }
          : {
              date,
              "Web Concessions": `${extra}${web_concessions}`,
              "Web Subscriptions": `${extra}${web_subscriptions}`,
              "Web Tickets": `${extra}${web_tickets}`,
              "Mobile Concessions": `${extra}${mobile_concessions}`,
              "Mobile Subscriptions": `${extra}${mobile_subscriptions}`,
              "Mobile Tickets": `${extra}${mobile_tickets}`,
              "Not specified": `${extra}${not_specified}`,
              Total: `${extra}${total}`
            }
    ) || [];

  const { mainHeading, subHeading } = useMainStyles();
  const { graphHeader, graphReportOverview, graphAnalysis } = useStyles();
  return (
    <div className={graphAnalysis}>
      <div className="graph">
        <h2 className={mainHeading}>{title}</h2>
        <div className={graphHeader}>
          <p className={subHeading}>{subTitle}</p>
          <div className={graphReportOverview}>
            <p className="total-data">{totalBreakdown}</p>
            <CSVLink
              data={generateCSVData(breakdown, extra) ?? []}
              filename={"sales-and-revenue.csv"}
            >
              <button disabled={isLoading} className="export-btn">
                <DownloadIcon color=" #A233E2" />
                EXPORT REPORT
              </button>
            </CSVLink>
          </div>
        </div>
        <div className="graph-chart">{chart}</div>
      </div>
      <div className="breakdown">
        <BreakdownText
          breakdownAmount={breakdownOne}
          title={subscriptionTitle}
          icon={<Subscription />}
          breakdownFurtherItems={breakdownFurtherItems}
        />
        <BreakdownText
          breakdownAmount={breakdownTwo}
          title={concessionTitle}
          icon={<Concession />}
        />
        <BreakdownText
          breakdownAmount={breakdownThree}
          title={ticketTitle}
          icon={<Ticket />}
        />
      </div>
    </div>
  );
};

export default GraphBreakdown;
