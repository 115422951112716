import React, { useEffect, useState } from "react";
import statsApi from "../../../../api/stats-api";
import { getRequestError } from "../../../../utils/functions";
import moment from "moment";
import useStyles from "../MainDashboard/MainDashboard.styles";
import DateSelector from "../../../../components/Inputs/DateSelector";
import Chart from "../../../../components/Chart";
import generateChart from "../utils/graphs";
import { CSVLink } from "react-csv";
import DownloadIcon from "@material-ui/icons/GetApp";

const LoyaltyRevenueGraph = ({ jwt }) => {
  const [startDate, setStartDate] = useState(
    moment()
      .subtract(1, "months")
      .format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [isLoading, setisLoading] = useState(false);
  const [loyaltyRevenue, setLoyaltyRevenue] = useState(null);

  const { filter, filterContainer } = useStyles({
    active: false
  });

  return (
    <div>
      <div>
        <Chart
          data={loyaltyRevenue || []}
          type="churn"
          title="Revenue breakdown"
          height={80}
          xLabel="Months"
          yLabel="Rate (%)"
          hasFullReport={false}
          loading={isLoading}
          filterComponent={
            <div className={filterContainer}>
              <CSVLink
                data={
                  loyaltyRevenue?.labels.map((item, index) => ({
                    date: item,
                    rate: loyaltyRevenue?.datasets[0].data[index]
                  })) ?? []
                }
                filename={"loyalty-revenue-breakdown.csv"}
              >
                <button disabled={isLoading} className="export-btn">
                  <DownloadIcon color="#A233E2" />
                  EXPORT REPORT
                </button>
              </CSVLink>
              <div className={filter}>
                <DateSelector
                  value={startDate}
                  onChange={e => setStartDate(moment(e).format("YYYY-MM-DD"))}
                />
              </div>
              <div className={filter}>
                <DateSelector
                  value={endDate}
                  onChange={e => setEndDate(moment(e).format("YYYY-MM-DD"))}
                />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default LoyaltyRevenueGraph;
