import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DashboardTable from "../../../../containers/DashboardTable";
import Chart from "../../../../components/Chart";
import statsApi from "../../../../api/stats-api";
import generateChart, { stencil } from "../utils/graphs";
import useStyles from "../styles";
import { dateFromISO, getRequestError } from "../../../../utils/functions";
import { generateMonths } from "../utils/time";
import { DataGrid } from "@material-ui/data-grid";
import Section from "../../../../components/Section";
import Check from "../../../../vectors/Check";
import Close from "../../../../vectors/Close";
import DropDown from "../../../../components/Inputs/Dropdown";
import moment from "moment";
import DateSelector from "../../../../components/Inputs/DateSelector";
import LoyaltyRevenueGraph from "../LoyaltyRevenueGraph/LoyaltyRevenueGraph";
import LoyaltyChurnRate from "../LoyaltyChurnRate/LoyaltyChurnRate";
import LoyaltyPieChart from "../LoyaltyPieChart/LoyaltyPieChart";

const LoyaltyStats = ({ jwt }) => {
  const { dashboardSection } = useStyles();

  return (
    <div>
      <DashboardTable
        data={[
          {
            cardName: "TOTAL REVENUE",
            cardValue: "₦ 500,000",
            cardText: "0"
          },
          {
            cardName: "TOTAL POINT ACCUMULATED",
            cardValue: "123 points",
            cardText: "0"
          },
          {
            cardName: "POINT REDEEMED",
            cardValue: "123 points",
            cardText: "0"
          },
          {
            cardName: "TOTAL DISCOUNT REDEEMED",
            cardValue: "50%",
            cardText: "0"
          }
        ]}
        loadingTable={false}
        tableTitle="Loyalty Stats"
      />
      <div className={dashboardSection}>
        <LoyaltyRevenueGraph jwt={jwt} />
      </div>
      <div className={dashboardSection}>
        <LoyaltyChurnRate jwt={jwt} />
      </div>
      <LoyaltyPieChart jwt={jwt} />
    </div>
  );
};

export default connect(state => {
  return {
    jwt: state.user.user.jwt
  };
})(LoyaltyStats);
