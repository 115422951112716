import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DashboardTable from "../../../containers/DashboardTable";
import Chart from "../../../components/Chart";
import statsApi from "../../../api/stats-api";
import generateChart, { doughnutData1, options, stencil } from "./utils/graphs";
import useStyles from "./styles";
import { Grid, Paper } from "@material-ui/core";
import { Doughnut } from "react-chartjs-2";
import { useRef } from "react";
import { getRequestError } from "../../../utils/functions";
import { generateMonths, months } from "./utils/time";
import DropDown from "../../../components/Inputs/Dropdown";
import DateSelector from "../../../components/Inputs/DateSelector";
import moment from "moment";
import Tickbox from "../../../components/Tickbox";

const DashboardHomePage = ({ jwt }) => {
  const [isLoadingOverviewTable, setIsLoadingOverviewTable] = useState(false);
  const [overviewTable, setOverviewTable] = useState(null);

  const [isLoadingTicketsCountGraph, setIsLoadingTicketsCountGraph] = useState(
    false
  );
  const [ticketsCountYear, setTicketsCountYear] = useState(
    moment(new Date()).format("YYYY") ?? "2022"
  );
  const [ticketsCountMonth, setTicketsCountMonth] = useState(
    moment(new Date()).format("MM") ?? "01"
  );
  const [ticketsCountGraph, setTicketsCountGraphData] = useState(stencil);
  const [ticketCountFilter, setTicketCountFiter] = useState("all");

  const [
    isLoadingTicketsReveneueGraph,
    setIsLoadingTicketsReveneueGraph
  ] = useState(false);
  const [ticketsReveneueYear, setTicketsReveneueYear] = useState(
    moment(new Date()).format("YYYY") ?? "2022"
  );
  const [ticketsReveneueMonth, setTicketsReveneueMonth] = useState(
    moment(new Date()).format("MM") ?? "01"
  );
  const [ticketsReveneueGraph, setTicketsReveneueGraphData] = useState(stencil);
  const [ticketRevenueFilter, setTicketRevenueFiter] = useState("all");

  const [
    isLoadingRedemptionRateGraph,
    setIsLoadingRedemptionRateGraph
  ] = useState(false);
  const [redemptionRateYear, setRedemptionRateYear] = useState(
    moment(new Date()).format("YYYY") ?? "2022"
  );
  const [redemptionRateGraph, setRedemptionRateGraphData] = useState(stencil);

  const [isLoadingRevenueGraph, setIsLoadingRevenueGraph] = useState(false);
  const [revenueYear, setRevenueYear] = useState(
    moment(new Date()).format("YYYY") ?? "2022"
  );
  const [revenueGraph, setRevenueGraphData] = useState(stencil);

  const [isLoadingChurnMRRGraph, setIsLoadingChurnMRRGraph] = useState(false);
  const [churnMRRYear, setChurnMRRYear] = useState(
    moment(new Date()).format("YYYY") ?? "2022"
  );
  const [churnMRRGraph, setChurnMRRGraphData] = useState(stencil);

  const [isLoadingVisitsGraph, setIsLoadingVisitsGraph] = useState(false);
  const [visitsYear, setVisitsYear] = useState(
    moment(new Date()).format("YYYY") ?? "2022"
  );
  const [visitsMonth, setVisitsMonth] = useState(
    moment(new Date()).format("MM") ?? "01"
  );
  const [visitsGraph, setVisitsGraphData] = useState(stencil);

  const [isLoadingUsersGraph, setIsLoadingUsersGraph] = useState(false);
  const [usersYear, setUsersYear] = useState(
    moment(new Date()).format("YYYY") ?? "2022"
  );
  const [usersGraph, setUsersGraphData] = useState(stencil);

  const [
    isLoadingSubConversionRateTable,
    setIsLoadingSubConversionRateTable
  ] = useState(false);
  const [subConversionRateTable, setSubConversionRateTable] = useState(null);
  const [subConversionRateStartDate, setSubConversionRateStartDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [subConversionRateEndDate, setSubConversionRateEndDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [isLoadingChurnRateGraph, setIsLoadingChurnRateGraph] = useState(false);
  const [churnRateYear, setChurnRateYear] = useState(
    moment(new Date()).format("YYYY") ?? "2022"
  );
  const [churnRateGraph, setChurnRateGraphData] = useState(stencil);

  const chart1 = useRef(null);
  const [legend1, setLegend1] = useState([]);

  const {
    dashboardSection,
    doughnutTitle,
    legendStyle,
    bigTitle,
    paperRoot,
    secondLegendBullet,
    firstLegendBullet,
    filterCont,
    ticketFilterCont
  } = useStyles();

  useEffect(() => {
    setLegend1(chart1.current.props.data.labels);
  }, []);

  useEffect(() => {
    setIsLoadingOverviewTable(true);

    statsApi
      .getStats(jwt)
      .then(res => {
        setOverviewTable(res.data.data);
        setIsLoadingOverviewTable(false);
      })
      .catch(e => getRequestError(e));
  }, [jwt]);

  useEffect(() => {
    setIsLoadingTicketsCountGraph(true);
    statsApi
      .getTicketsSoldCount(jwt, ticketsCountMonth, ticketsCountYear)
      .then(res => {
        const chart = generateChart(
          res.data?.data?.map(item => [
            item?.web_total,
            item?.mobile_total,
            item?.not_specified,
            item?.web_tickets,
            item?.web_concessions,
            item?.web_tickets_concessions,
            item?.web_not_specified,
            item?.mobile_tickets,
            item?.mobile_concessions,
            item?.mobile_tickets_concessions,
            item?.mobile_not_specified
          ]),
          [
            `Web sales`,
            "Mobile sales",
            "No platform",
            "Web tickets",
            "Web concessions",
            "Web tickets + concessions",
            "Web (no purchase type)",
            "Mobile tickets",
            "Mobile concessions",
            "Mobile tickets + concessions",
            "Mobile (no purchase type)"
          ],
          res.data?.data?.map((item, i) => `${i + 1}`)
        );
        console.log(chart);
        setTicketsCountGraphData({
          labels: chart.labels,
          datasets: chart.datasets.filter(item =>
            ticketCountFilter === "all"
              ? true
              : item?.label
                  .toLowerCase()
                  .includes(ticketCountFilter.toLowerCase())
          )
        });
        setIsLoadingTicketsCountGraph(false);
      })
      .catch(e => getRequestError(e));
  }, [jwt, ticketsCountMonth, ticketsCountYear, ticketCountFilter]);

  useEffect(() => {
    setIsLoadingTicketsReveneueGraph(true);
    statsApi
      .getTicketsRevenue(jwt, ticketsReveneueMonth, ticketsReveneueYear)
      .then(res => {
        const chart = generateChart(
          res.data?.data?.map(item => [
            item?.web_total,
            item?.mobile_total,
            item?.not_specified,
            item?.web_tickets,
            item?.web_concessions,
            item?.web_tickets_concessions,
            item?.web_not_specified,
            item?.mobile_tickets,
            item?.mobile_concessions,
            item?.mobile_tickets_concessions,
            item?.mobile_not_specified
          ]),
          [
            "Web sales (₦)",
            "Mobile sales (₦)",
            "No platform (₦)",
            "Web tickets (₦)",
            "Web concessions (₦)",
            "Web tickets + concessions (₦)",
            "Web (no purchase type) (₦)",
            "Mobile tickets (₦)",
            "Mobile concessions (₦)",
            "Mobile tickets + concessions (₦)",
            "Mobile (no purchase type) (₦)"
          ],
          res.data?.data?.map((item, i) => `${i + 1}`)
        );
        setTicketsReveneueGraphData({
          labels: chart.labels,
          datasets: chart.datasets.filter(item =>
            ticketRevenueFilter === "all"
              ? true
              : item?.label
                  .toLowerCase()
                  .includes(ticketRevenueFilter.toLowerCase())
          )
        });
        setIsLoadingTicketsReveneueGraph(false);
      })
      .catch(e => getRequestError(e));
  }, [jwt, ticketsReveneueMonth, ticketsReveneueYear, ticketRevenueFilter]);

  useEffect(() => {
    setIsLoadingRedemptionRateGraph(true);
    statsApi
      .getRedemptionRateTwo(jwt, redemptionRateYear)
      .then(res => {
        setRedemptionRateGraphData(
          generateChart(
            res.data?.data.map(item => [item.rate]),
            ["Rate"],
            generateMonths(res.data?.data?.map(item => item?.month))
          )
        );
        setIsLoadingRedemptionRateGraph(false);
      })
      .catch(e => getRequestError(e));
  }, [jwt, redemptionRateYear]);

  useEffect(() => {
    setIsLoadingRevenueGraph(true);
    statsApi
      .getRevenue(jwt, revenueYear)
      .then(res => {
        setRevenueGraphData(
          generateChart(
            res.data?.data?.map(item => [item?.total]),
            ["Revenue"],
            generateMonths(res.data?.data?.map(item => item?.month))
          )
        );
        setIsLoadingRevenueGraph(false);
      })
      .catch(e => getRequestError(e));
  }, [jwt, revenueYear]);

  useEffect(() => {
    setIsLoadingChurnMRRGraph(true);
    statsApi
      .getChurnMRR(jwt, churnMRRYear)
      .then(res => {
        setChurnMRRGraphData(
          generateChart(
            res.data?.data?.map(item => [item?.total]),
            ["Churn MRR"],
            generateMonths(res.data?.data?.map(item => item?.month))
          )
        );
        setIsLoadingChurnMRRGraph(false);
      })
      .catch(e => getRequestError(e));
  }, [jwt, churnMRRYear]);

  useEffect(() => {
    setIsLoadingVisitsGraph(true);
    statsApi
      .getUsersVisit(jwt, visitsMonth, visitsYear)
      .then(res => {
        setVisitsGraphData(
          generateChart(
            res.data?.data?.map(item => [
              item?.visit_count?.web,
              item?.visit_count?.mobile
            ]),
            ["Usage by web", "Usage by mobile app "],
            res.data?.data?.map((item, i) => `${i + 1}`)
          )
        );
        setIsLoadingVisitsGraph(false);
      })
      .catch(e => getRequestError(e));
  }, [jwt, visitsMonth, visitsYear]);

  useEffect(() => {
    setIsLoadingUsersGraph(true);
    statsApi
      .getUsersStats(jwt, usersYear)
      .then(res => {
        setUsersGraphData(
          generateChart(
            res.data?.data?.map(item => [item[Object.keys(item)[0]]]),
            ["Users"],
            generateMonths(res.data?.data?.map(item => Object.keys(item)[0]))
          )
        );
        setIsLoadingUsersGraph(false);
      })
      .catch(e => getRequestError(e));
  }, [jwt, usersYear]);

  useEffect(() => {
    if (subConversionRateEndDate >= subConversionRateStartDate) {
      setIsLoadingSubConversionRateTable(true);
      statsApi
        .getSubscriptionConversionRate(
          jwt,
          subConversionRateStartDate,
          subConversionRateEndDate
        )
        .then(res => {
          setSubConversionRateTable(res.data?.data);
          setIsLoadingSubConversionRateTable(false);
        })
        .catch(e => getRequestError(e));
    }
  }, [jwt, subConversionRateStartDate, subConversionRateEndDate]);

  useEffect(() => {
    setIsLoadingChurnRateGraph(true);
    statsApi
      .getCustomerChurnRate(jwt, churnRateYear)
      .then(res => {
        setChurnRateGraphData(
          generateChart(
            res.data?.data.map(item => [item?.rate.toFixed(2)]),
            ["Rate"],
            generateMonths(res.data?.data?.map(item => item?.month))
          )
        );
        setIsLoadingChurnRateGraph(false);
      })
      .catch(e => getRequestError(e));
  }, [jwt, churnRateYear]);

  const inActiveSubscribers = overviewTable?.subscription_statistics?.inactive;
  const activeSubscribers = overviewTable?.subscription_statistics?.active;
  const totalSubscribers =
    inActiveSubscribers && activeSubscribers
      ? inActiveSubscribers + activeSubscribers
      : 0;
  return (
    <div>
      <DashboardTable
        data={[
          {
            cardName: "NO. OF TICKETS SOLD",
            cardValue:
              overviewTable?.total_number_of_items_sold?.tickets?.toLocaleString() ||
              "N/A",
            cardText: "0"
          },
          {
            cardName: "TOTAL REVENUE",
            cardValue:
              `₦${overviewTable?.total_online_revenue?.toLocaleString()}` ||
              "N/A",
            cardText: "0"
          },
          {
            cardName: "TOTAL USERS",
            cardValue:
              overviewTable?.total_number_of_reg_users?.toLocaleString() ||
              "N/A",
            cardText: "0"
          },
          {
            cardName: "TOTAL NUMBER OF TRANSACTION",
            cardValue:
              overviewTable?.total_number_of_transactions?.toLocaleString() ||
              "N/A",
            cardText: "0"
          }
        ]}
        loadingTable={isLoadingOverviewTable}
        tableTitle="Overall metrics"
      />
      <div className={dashboardSection}>
        <Chart
          data={
            ticketsCountGraph
              ? {
                  labels: ticketsCountGraph.labels.map(
                    item =>
                      `${item}-${
                        ticketsCountMonth.length >= 3
                          ? ticketsCountMonth.substring(1, 3)
                          : ticketsCountMonth
                      }-${ticketsCountYear}`
                  ),
                  datasets: ticketsCountGraph.datasets.filter(
                    item =>
                      item.label === "Web sales" ||
                      item.label === "Mobile sales" ||
                      item.label === "No platform"
                  )
                }
              : stencil
          }
          labelCb={(index, label) =>
            label.toLowerCase().includes("web")
              ? [
                  `Web tickets: ${
                    ticketsCountGraph.datasets.find(
                      item => item?.label === "Web tickets"
                    )?.data[index]
                  }`,
                  `Web concessions: ${
                    ticketsCountGraph.datasets.find(
                      item => item?.label === "Web concessions"
                    )?.data[index]
                  }`,
                  `Web tickets + concessions: ${
                    ticketsCountGraph.datasets.find(
                      item => item?.label === "Web tickets + concessions"
                    )?.data[index]
                  }`,
                  `Web (no purchase type): ${
                    ticketsCountGraph.datasets.find(
                      item => item?.label === "Web (no purchase type)"
                    )?.data[index]
                  }`
                ]
              : label.toLowerCase().includes("mobile")
              ? [
                  `Mobile tickets: ${
                    ticketsCountGraph.datasets.find(
                      item => item?.label === "Mobile tickets"
                    )?.data[index]
                  }`,
                  `Mobile concessions: ${
                    ticketsCountGraph.datasets.find(
                      item => item?.label === "Mobile concessions"
                    )?.data[index]
                  }`,
                  `Mobile tickets + concessions: ${
                    ticketsCountGraph.datasets.find(
                      item => item?.label === "Mobile tickets + concessions"
                    )?.data[index]
                  }`,
                  `Mobile (no purchase type): ${
                    ticketsCountGraph.datasets.find(
                      item => item?.label === "Mobile (no purchase type)"
                    )?.data[index]
                  }`
                ]
              : ""
          }
          type="tickets"
          title="Sales breakdown"
          height={80}
          xLabel="Days"
          yLabel="Number of tickets"
          hasFullReport={false}
          loading={isLoadingTicketsCountGraph}
          filterComponent={
            <div className={filterCont}>
              <DropDown
                menuItems={months.map((item, i) => ({
                  value: i.toString().length === 1 ? `0${i + 1}` : i + 1,
                  name: item
                }))}
                value={ticketsCountMonth}
                onChange={e => setTicketsCountMonth(e.target.value)}
              />
              <DropDown
                menuItems={[
                  "2022",
                  "2021",
                  "2020",
                  "2019",
                  "2018",
                  "2017",
                  "2016",
                  "2015"
                ].map(item => ({ value: item, name: item }))}
                value={ticketsCountYear}
                onChange={e => setTicketsCountYear(e.target.value)}
              />
            </div>
          }
          leftFilterComponent={
            <div className={ticketFilterCont}>
              <Tickbox
                bgColor="#1F2327"
                onChange={() => setTicketCountFiter("all")}
                text="View All"
                tickColor="#A233E2"
                isTicked={ticketCountFilter === "all"}
              />
              <Tickbox
                bgColor="#A233E2"
                onChange={() => setTicketCountFiter("web")}
                text="Web Sales"
                tickColor="#FFFFFF"
                isTicked={ticketCountFilter === "web"}
              />
              <Tickbox
                bgColor="#60CED1"
                onChange={() => setTicketCountFiter("mobile")}
                text="Mobile Sales"
                tickColor="#FFFFFF"
                isTicked={ticketCountFilter === "mobile"}
              />
            </div>
          }
        />
      </div>
      <div className={dashboardSection}>
        <Chart
          data={
            ticketsReveneueGraph
              ? {
                  labels: ticketsCountGraph.labels.map(
                    item =>
                      `${item}-${
                        ticketsCountMonth.length >= 3
                          ? ticketsCountMonth.substring(1, 3)
                          : ticketsCountMonth
                      }-${ticketsCountYear}`
                  ),
                  datasets: ticketsReveneueGraph.datasets.filter(
                    item =>
                      item.label === "Web sales (₦)" ||
                      item.label === "Mobile sales (₦)" ||
                      item.label === "No platform (₦)"
                  )
                }
              : stencil
          }
          type="tickets"
          title="Sales revenue breakdown"
          height={80}
          xLabel="Days"
          yLabel="Revenue (₦)"
          hasFullReport={false}
          loading={isLoadingTicketsReveneueGraph}
          labelCb={(index, label) =>
            label.toLowerCase().includes("web")
              ? [
                  `Web tickets (₦): ${
                    ticketsReveneueGraph.datasets.find(
                      item => item?.label === "Web tickets (₦)"
                    )?.data[index]
                  }`,
                  `Web concessions (₦): ${
                    ticketsReveneueGraph.datasets.find(
                      item => item?.label === "Web concessions (₦)"
                    )?.data[index]
                  }`,
                  `Web tickets + concessions (₦): ${
                    ticketsReveneueGraph.datasets.find(
                      item => item?.label === "Web tickets + concessions (₦)"
                    )?.data[index]
                  }`,
                  `Web (no purchase type) (₦): ${
                    ticketsReveneueGraph.datasets.find(
                      item => item?.label === "Web (no purchase type) (₦)"
                    )?.data[index]
                  }`
                ]
              : label.toLowerCase().includes("mobile")
              ? [
                  `Mobile tickets (₦): ${
                    ticketsReveneueGraph.datasets.find(
                      item => item?.label === "Mobile tickets (₦)"
                    )?.data[index]
                  }`,
                  `Mobile concessions (₦): ${
                    ticketsReveneueGraph.datasets.find(
                      item => item?.label === "Mobile concessions (₦)"
                    )?.data[index]
                  }`,
                  `Mobile tickets + concessions (₦): ${
                    ticketsReveneueGraph.datasets.find(
                      item => item?.label === "Mobile tickets + concessions (₦)"
                    )?.data[index]
                  }`,
                  `Mobile (no purchase type) (₦): ${
                    ticketsReveneueGraph.datasets.find(
                      item => item?.label === "Mobile (no purchase type) (₦)"
                    )?.data[index]
                  }`
                ]
              : ""
          }
          filterComponent={
            <div className={filterCont}>
              <DropDown
                menuItems={months.map((item, i) => ({
                  value: i.toString().length === 1 ? `0${i + 1}` : i + 1,
                  name: item
                }))}
                value={ticketsReveneueMonth}
                onChange={e => setTicketsReveneueMonth(e.target.value)}
              />
              <DropDown
                menuItems={[
                  "2022",
                  "2021",
                  "2020",
                  "2019",
                  "2018",
                  "2017",
                  "2016",
                  "2015"
                ].map(item => ({ value: item, name: item }))}
                value={ticketsReveneueYear}
                onChange={e => setTicketsReveneueYear(e.target.value)}
              />
            </div>
          }
          leftFilterComponent={
            <div className={ticketFilterCont}>
              <Tickbox
                bgColor="#1F2327"
                onChange={() => setTicketRevenueFiter("all")}
                text="View All"
                tickColor="#A233E2"
                isTicked={ticketRevenueFilter === "all"}
              />
              <Tickbox
                bgColor="#A233E2"
                onChange={() => setTicketRevenueFiter("web")}
                text="Web Sales"
                tickColor="#FFFFFF"
                isTicked={ticketRevenueFilter === "web"}
              />
              <Tickbox
                bgColor="#60CED1"
                onChange={() => setTicketRevenueFiter("mobile")}
                text="Mobile Sales"
                tickColor="#FFFFFF"
                isTicked={ticketRevenueFilter === "mobile"}
              />
            </div>
          }
        />
      </div>
      <div className={dashboardSection}>
        <Chart
          data={redemptionRateGraph ?? stencil}
          type="tickets"
          title="Redemption rate"
          height={80}
          xLabel="Months"
          yLabel="Rate"
          hasFullReport={false}
          loading={isLoadingRedemptionRateGraph}
          filterComponent={
            <DropDown
              menuItems={[
                "2022",
                "2021",
                "2020",
                "2019",
                "2018",
                "2017",
                "2016",
                "2015"
              ].map(item => ({ value: item, name: item }))}
              value={redemptionRateYear}
              onChange={e => setRedemptionRateYear(e.target.value)}
            />
          }
        />
      </div>
      <DashboardTable
        data={[
          {
            cardName: "TOTAL SUBSCRIPTION REVENUE",
            cardValue:
              `₦${overviewTable?.total_subscription_revenue?.toLocaleString()}` ||
              "N/A",
            cardText: "0"
          },
          {
            cardName: "TOTAL ONLINE REVENUE",
            cardValue:
              `₦${overviewTable?.total_online_revenue?.toLocaleString()}` ||
              "N/A",
            cardText: "0"
          },
          {
            cardName: "TOTAL CONCESSION REVENUE",
            cardValue:
              `₦${overviewTable?.total_concession_revenue?.toLocaleString()}` ||
              "N/A",
            cardText: "0"
          },
          {
            cardName: "TOTAL TICKET REVENUE",
            cardValue:
              `₦${overviewTable?.total_ticket_purchase_revenue?.toLocaleString()}` ||
              "N/A",
            cardText: "0"
          }
        ]}
        loadingTable={isLoadingOverviewTable}
        tableTitle="Revenue"
      />
      <div className={dashboardSection}>
        <Chart
          data={revenueGraph ?? stencil}
          type="revenue"
          title="Revenue breakdown"
          height={80}
          xLabel="Months"
          yLabel="Amount"
          hasFullReport={false}
          loading={isLoadingRevenueGraph}
          filterComponent={
            <DropDown
              menuItems={[
                "2022",
                "2021",
                "2020",
                "2019",
                "2018",
                "2017",
                "2016",
                "2015"
              ].map(item => ({ value: item, name: item }))}
              value={revenueYear}
              onChange={e => setRevenueYear(e.target.value)}
            />
          }
        />
      </div>
      <div className={dashboardSection}>
        <Chart
          data={churnMRRGraph ?? stencil}
          type="revenue"
          title="Churn MRR"
          height={80}
          xLabel="Months"
          yLabel="Amount"
          hasFullReport={false}
          loading={isLoadingChurnMRRGraph}
          filterComponent={
            <DropDown
              menuItems={[
                "2022",
                "2021",
                "2020",
                "2019",
                "2018",
                "2017",
                "2016",
                "2015"
              ].map(item => ({ value: item, name: item }))}
              value={churnMRRYear}
              onChange={e => setChurnMRRYear(e.target.value)}
            />
          }
        />
      </div>
      <DashboardTable
        data={[
          {
            cardName: "TOTAL ONLINE CUSTOMERS",
            cardValue:
              overviewTable?.total_number_of_reg_users?.toLocaleString() ||
              "N/A",
            cardText: "0"
          },
          {
            cardName: "TOTAL ACTIVE USERS",
            cardValue: activeSubscribers?.toLocaleString() || "N/A",
            cardText: "0"
          },
          {
            cardName: "TOTAL UNSUBSCRIBED USERS",
            cardValue: inActiveSubscribers?.toLocaleString() || "N/A",
            cardText: "0"
          },
          {
            cardName: "REPEAT USERS",
            cardValue:
              `${overviewTable?.percentage_returning_customer?.toFixed(1)}%` ||
              "N/A",
            cardText: "0"
          }
        ]}
        loadingTable={isLoadingOverviewTable}
        tableTitle="Users"
      />
      <Grid container className={dashboardSection}>
        <Grid item xs={4}>
          <Paper elevation={0} className={paperRoot}>
            <div className={doughnutTitle}>Total no of subscribers</div>
            <div className={bigTitle}>{totalSubscribers}</div>
            <div style={{ height: "300px", marginBottom: "30px" }}>
              <Doughnut
                ref={chart1}
                data={doughnutData1({
                  inactiveSubscribers: inActiveSubscribers || 0,
                  activeSubscribers: activeSubscribers
                })}
                options={options}
              />
            </div>
            <div>
              <ul className="chart-ul">
                {legend1 &&
                  legend1.map((item, i) => {
                    return (
                      <div key={item} className={legendStyle}>
                        <div
                          className={
                            i === 0
                              ? firstLegendBullet
                              : secondLegendBullet || 0
                          }
                        />
                        <span>{item} </span>
                        <span>
                          {i === 0
                            ? `${Math.round(
                                (inActiveSubscribers / totalSubscribers) * 100
                              )}%` || 0
                            : `${Math.round(
                                (activeSubscribers / totalSubscribers) * 100
                              )}%` || 0}
                        </span>
                      </div>
                    );
                  })}
              </ul>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Chart
            data={usersGraph ?? stencil}
            type="users"
            title="Total users breakdown"
            height={150}
            xLabel="Months"
            yLabel="Number of signups"
            hasFullReport={false}
            loading={isLoadingUsersGraph}
            filterComponent={
              <DropDown
                menuItems={[
                  "2022",
                  "2021",
                  "2020",
                  "2019",
                  "2018",
                  "2017",
                  "2016",
                  "2015"
                ].map(item => ({ value: item, name: item }))}
                value={usersYear}
                onChange={e => setUsersYear(e.target.value)}
              />
            }
          />
        </Grid>
      </Grid>
      <div className={dashboardSection}>
        <Chart
          data={visitsGraph ?? stencil}
          type="users"
          title="Usage by web/mobile app "
          height={80}
          xLabel="Days"
          yLabel="Number of visits"
          hasFullReport={false}
          loading={isLoadingVisitsGraph}
          filterComponent={
            <div className={filterCont}>
              <DropDown
                menuItems={months.map((item, i) => ({
                  value: i.toString().length === 1 ? `0${i + 1}` : i + 1,
                  name: item
                }))}
                value={visitsMonth}
                onChange={e => setVisitsMonth(e.target.value)}
              />
              <DropDown
                menuItems={[
                  "2022",
                  "2021",
                  "2020",
                  "2019",
                  "2018",
                  "2017",
                  "2016",
                  "2015"
                ].map(item => ({ value: item, name: item }))}
                value={visitsYear}
                onChange={e => setVisitsYear(e.target.value)}
              />
            </div>
          }
        />
      </div>
      <DashboardTable
        data={[
          {
            cardName: "CONVERSION RATE",
            cardValue: subConversionRateTable?.conversionRate
              ? subConversionRateTable?.conversionRate * 100 + "%"
              : "N/A",
            cardText: "0"
          },
          {
            cardName: "NEW SUBSCRIPTION PURCHASE",
            cardValue:
              subConversionRateTable?.newSignUpUsers?.toLocaleString() || "N/A",
            cardText: "0"
          },
          {
            cardName: "NEW SIGNUP USERS",
            cardValue:
              subConversionRateTable?.newSubscriptionPurchase?.toLocaleString() ||
              "N/A",
            cardText: "0"
          }
        ]}
        loadingTable={isLoadingSubConversionRateTable}
        tableTitle="Subscription Conversion Rate"
        filterComponent={
          <div className={filterCont}>
            <DateSelector
              value={subConversionRateStartDate}
              onChange={e =>
                setSubConversionRateStartDate(moment(e).format("YYYY-MM-DD"))
              }
            />
            <DateSelector
              value={subConversionRateEndDate}
              onChange={e =>
                setSubConversionRateEndDate(moment(e).format("YYYY-MM-DD"))
              }
            />
          </div>
        }
      />
      <div className={dashboardSection}>
        <Chart
          data={churnRateGraph ?? stencil}
          type="tickets"
          title="Churn rate"
          height={80}
          xLabel="Months"
          yLabel="Rate"
          hasFullReport={false}
          loading={isLoadingChurnRateGraph}
          filterComponent={
            <DropDown
              menuItems={[
                "2022",
                "2021",
                "2020",
                "2019",
                "2018",
                "2017",
                "2016",
                "2015"
              ].map(item => ({ value: item, name: item }))}
              value={churnRateYear}
              onChange={e => setChurnRateYear(e.target.value)}
            />
          }
        />
      </div>
    </div>
  );
};

export default connect(state => {
  return {
    jwt: state.user.user.jwt
  };
})(DashboardHomePage);
