import React, { useEffect, useRef, useState } from "react";
import useStyles from "../styles";
import { Grid, Paper } from "@material-ui/core";
import { Doughnut } from "react-chartjs-2";
import { doughnutData1, options } from "../utils/graphs";

const UsersPieChart = ({ jwt }) => {
  const chart1 = useRef(null);
  const [legend1, setLegend1] = useState([]);

  const {
    dashboardSection,
    doughnutTitle,
    legendStyle,
    bigTitle,
    paperRoot,
    secondLegendBullet,
    firstLegendBullet
  } = useStyles();

  useEffect(() => {
    setLegend1(chart1.current.props.data.labels);
  }, []);

  const inActiveSubscribers = 75000;
  const activeSubscribers = 175000;
  const totalSubscribers = inActiveSubscribers + activeSubscribers;

  return (
    <Grid container className={dashboardSection}>
      <Grid item xs={6}>
        <Paper elevation={0} className={paperRoot}>
          <div className={doughnutTitle}>Total no of subscribers</div>
          <div className={bigTitle}>{totalSubscribers}</div>
          <div style={{ height: "300px", marginBottom: "30px" }}>
            <Doughnut
              ref={chart1}
              data={doughnutData1({
                inactiveSubscribers: inActiveSubscribers,
                activeSubscribers
              })}
              options={options}
            />
          </div>
          <div>
            <ul className="chart-ul">
              {legend1 &&
                legend1.map((item, i) => {
                  return (
                    <div key={item} className={legendStyle}>
                      <div
                        className={
                          i === 0 ? firstLegendBullet : secondLegendBullet || 0
                        }
                      />
                      <span>{item} </span>
                      <span>
                        {i === 0
                          ? `${Math.round(
                              (inActiveSubscribers / totalSubscribers) * 100
                            )}%` || 0
                          : `${Math.round(
                              (activeSubscribers / totalSubscribers) * 100
                            )}%` || 0}
                      </span>
                    </div>
                  );
                })}
            </ul>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UsersPieChart;
