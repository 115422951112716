import axios from "axios";

export default {
  fetchConcessions: (token, cinema) =>
    axios
      .get(`/concessions/${cinema}?add_mobile_device=true`, {
        headers: { Authorization: token }
      })
      .then(res => res),
  fetchRosettaConcessions: (token, cinema) =>
    axios
      .get(`/concessions/rosetta/${cinema}`, {
        headers: { Authorization: token }
      })
      .then(res => res),
  uploadConcessionImage: (body, token) =>
    axios
      .post(`/concessions/upload-image`, body, {
        headers: { Authorization: token }
      })
      .then(res => res),
  editComboDeal: (body, id, token) =>
    axios
      .put(`/concessions/combo-deals/${id}`, body, {
        headers: { Authorization: token }
      })
      .then(res => res),
  createComboDeal: (body, token) =>
    axios
      .post(`/concessions/combo-deals`, body, {
        headers: { Authorization: token }
      })
      .then(res => res),
  deleteComboDeal: (token, id) =>
    axios
      .delete(`/concessions/combo-deals/${id}`, {
        headers: { Authorization: token }
      })
      .then(res => res),
  deleteComboDealItems: (token, ids) =>
    axios
      .delete(`/concessions/combo-deals/items/${ids}`, {
        headers: { Authorization: token }
      })
      .then(res => res),
  activate: (token, id) =>
    axios
      .put(
        `/concessions/activate/${id}`,
        {},
        {
          headers: { Authorization: token }
        }
      )
      .then(res => res),
  deactivate: (token, id, cinema_id) =>
    axios
      .put(
        `/concessions/deactivate`,
        { rosetta_product_ids: [id], cinema_id },
        {
          headers: { Authorization: token }
        }
      )
      .then(res => res)
};
