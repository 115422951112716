import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Section from "../../../components/Section";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import Point from "../../../components/Section/Point";
import api from "../../../api/users-api";
import Avatar from "../../../components/Avatar";
import useStyles from "./styles";
import moment from "moment";
import GoBack from "../../../components/Button/GoBack";
import { CircularLoad } from "../../../components/Loader";
import UserSlideIn from "../../../containers/UserList/UserSlideIn";
import { DataGrid } from "@material-ui/data-grid";
import DeleteDialog from "../../../components/DeleteDialog";
import Camera from "../../../vectors/Camera";
import { useDispatch } from "react-redux";
import types from "../../../redux/types";
import { getRequestError } from "../../../utils/functions";

const loyaltyColumns = [
  { field: "details", headerName: "Details", width: 500 },
  { field: "date", headerName: "Date", width: 200 },
  { field: "points", headerName: "Points", width: 150 }
];

const dummy = [
  {
    details: "Purchase of 2 tickets for the movie 'The Avengers'",
    date: "01/08/2020",
    points: "₦2,500",
    key: 1,
    id: 1
  },
  {
    details: "Purchase of 1 ticket for the movie 'Black Widow'",
    date: "02/15/2022",
    points: "₦1,500",
    key: 2,
    id: 2
  },
  {
    details: "Purchase of 3 tickets for the movie 'Spider-Man: No Way Home'",
    date: "03/20/2022",
    points: "₦3,000",
    key: 3,
    id: 3
  },
  {
    details:
      "Purchase of 1 ticket for the movie 'Shang-Chi and the Legend of the Ten Rings'",
    date: "04/15/2022",
    points: "₦1,500",
    key: 4,
    id: 4
  }
];

const LoyaltyUserProfilePage = ({ user, match }) => {
  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    dateOfBirth: null,
    gender: "",
    createdAt: null,
    preferredBillingCycle: "",
    subPlan: "",
    profileImage: null,
    cardNumber: ""
  });
  const [pageLoading, setPageLoading] = useState(false);
  const [loyaltyHistory, setLoyaltyHistory] = useState(dummy);
  const [showPanel, setShowPanel] = useState(false);
  const dispatch = useDispatch();

  console.log(loyaltyHistory);

  const { ALERT_OPEN } = types;
  //
  const userID = match.params.id;
  const {
    backBtn,
    sectionContainer,
    titleStyle,
    section,
    profileHead,
    profileTitle,
    profileValue,
    profileContent,
    profileContentDivider,
    profileHeading
  } = useStyles();

  const refreshList = () => {
    setPageLoading(true);
  };

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowPanel(open);
  };

  return pageLoading ? (
    <div>
      <CircularLoad show={true} />
    </div>
  ) : (
    <>
      <div className={backBtn}>
        <GoBack link="/dashboard/users/loyalty" />
      </div>
      <div className={sectionContainer}>
        <Section>
          <div className={section}>
            <Grid container alignItems="center" justify="space-between">
              <Grid item>
                <Grid container justify="center" direction="column">
                  <h2 className={profileHeading}>Profile</h2>
                </Grid>
              </Grid>
              <Button
                onClick={toggleDrawer(true)}
                variant="contained"
                color="primary"
              >
                EDIT PROFILE
              </Button>
            </Grid>
            <Grid
              className={profileContent}
              spacing={10}
              container
              alignItems="center"
            >
              <Grid xs={6} item>
                <h3 className={profileTitle}>Name</h3>
                <p className={profileValue}>Emmanuel Biolatiri</p>
              </Grid>
              <Grid xs={6} item>
                <h3 className={profileTitle}>Email</h3>
                <p className={profileValue}>emmanuelbiolatiri49@gmail.com</p>
              </Grid>
              <div className={profileContentDivider} />
            </Grid>
            <Grid
              className={profileContent}
              spacing={10}
              container
              alignItems="center"
            >
              <Grid xs={6} item>
                <h3 className={profileTitle}>Date Created</h3>
                <p className={profileValue}>01/08/2019</p>
              </Grid>
              <Grid xs={6} item>
                <h3 className={profileTitle}>Last Visit</h3>
                <p className={profileValue}>Today at 11:30pm</p>
              </Grid>
              <div className={profileContentDivider} />
            </Grid>
            <Grid
              className={profileContent}
              spacing={10}
              container
              alignItems="center"
            >
              <Grid xs={6} item>
                <h3 className={profileTitle}>Loyalty Point Accumulated</h3>
                <p className={profileValue}>123 points</p>
              </Grid>
              <Grid xs={6} item>
                <h3 className={profileTitle}>Points Redeemed </h3>
                <p className={profileValue}>123 points</p>
              </Grid>
              <div className={profileContentDivider} />
            </Grid>
          </div>
        </Section>
      </div>
      <div className={sectionContainer}>
        <Section title="Loyalty Transaction History" titleStyle={titleStyle}>
          <div className={section}>
            <div style={{ width: "100%", marginTop: "20px" }}>
              {loyaltyHistory && loyaltyHistory.length > 0 ? (
                <DataGrid
                  rows={loyaltyHistory}
                  columns={loyaltyColumns}
                  pageSize={15}
                  rowHeight={50}
                  headerHeight={60}
                  disableColumnMenu={true}
                  autoHeight={true}
                />
              ) : (
                <p>No transactions made yet</p>
              )}
            </div>
          </div>
        </Section>
      </div>
      <UserSlideIn
        open={showPanel}
        close={() => setShowPanel(false)}
        onClose={() => toggleDrawer(false)}
        jwt={user.jwt}
        refreshList={refreshList}
        profile={{
          firstName: profile.firstName,
          lastName: profile.lastName,
          email: profile.email,
          mobile: profile.mobile,
          dateOfBirth: profile.dateOfBirth
        }}
        formTitle="Edit Profile"
        type="edit"
        userID={userID}
      />
    </>
  );
};

const mapStateToProps = state => ({
  user: state.user.user
});

export default connect(mapStateToProps)(withRouter(LoyaltyUserProfilePage));
